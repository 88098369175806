import React, { Fragment } from 'react';
import moment from 'moment';
import "./WorkFlowSalesOrderTrackingForm.scss";
import { Tooltip } from '@material-ui/core';
import { BaseFormViewModel } from '../common/ViewModel';
import { SalesOrderTrackingWorkFlowDTO } from '../workflow/SalesOrderTracking';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    Button,
    Progress,
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    FormGroup,
    Input
} from 'reactstrap';
import { AppPageForm, FlexRow, toasty, FlexCenterRow } from '../common/forms/FormElements';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { Can } from '../Can';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import SalesOrderTrackingSlideForm from './SalesOrderTrackingSlideForm';
import classnames from 'classnames';
import { ShippingDTO } from './Shipping';
import { v4 as uuid } from 'uuid';
//Suports the font-awesome icon picker
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

export default class WorkFlowSalesOrderTrackingForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.salesOrderTrackingSlideFormRef = React.createRef();

        let stateBase = Object.assign(
            {
                salesOrderTrackingId: null,
                workCenterId: 0,
                workflowTracking: new SalesOrderTrackingWorkFlowDTO(),
                shippingTracking: new ShippingDTO(),
                formIsError: false,
                isCompleted: false,
                isReadyToComplete: false,
                forShipping: false,
                completionPercentage: 0,
                hasLoaded: false,
                errorResponse: {}
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    clearSaving = () => this.setState({ saving: false });
    handleSaveError = (err) => handleFormSaveError(this, err);

    notifySuccess = () => toasty.success('Order Tracking Saved', `Saved successfully.`);
    notifyError = (message) => toasty.error('Could Not Associate Form', message);

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    }

    componentDidMount = async () => {
        if (this.props.location.pathname.includes(AppNavPaths.Shipping)) {
            this.setState({
                forShipping: true
            });
        }

        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps && (  
                (this.props.match.params.wcid !== (prevProps.match.params ?? {}).wcid)
                || (this.props.match.params.sotid !== (prevProps.match.params ?? {}).sotid)
                || (this.props.match.params.shpid !== (prevProps.match.params ?? {}).shpid)
            )
        ){
            await this.populateState();
        }
    }

    onCloseClicked = () => {
        let { wcid, shpid } = { ...this.props.match.params };

        if (!!shpid) {
            this.props.history.push(`${AppNavPaths.Shipping}`);
        } else {
            this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${wcid}`);
        }
    }

    onShowSalesOrderTrackingClicked = async () => {
        this.salesOrderTrackingSlideFormRef.current.open();
    }

    async populateState() {
        await this.setState({
            loading: true,
        });

        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { wcid, sotid, shpid} = { ...this.props.match.params };

            if (!!wcid && !!sotid) {
                let [srtwfresp] = await Promise.all([util.fetch.js(ApiRoutes.workCenter.workflowTracking(wcid, sotid))]);

                if (!!srtwfresp.error) {
                    let errors = {};
                    errors["Exception"] = srtwfresp.error;
                    
                    await this.setState({
                        loading: false,
                        formIsError: true,
                        errors: errors
                    });
                } else {

                    let completionPercentage = 0;
                    let formTotal = srtwfresp.totalFormCount;
                    let completedCount = srtwfresp.completedFormCount;

                    if (formTotal != 0) {
                        completionPercentage = Math.round((completedCount / formTotal) * 100);
                    } else {
                        completionPercentage = 100;
                    }

                    let isReadyToComplete = (
                            (
                                (formTotal == completedCount) || formTotal == 0
                            ) //All forms are complete
                        ) && !!(srtwfresp.workCenterComplete) && (srtwfresp.workCenterStatus !== 'Complete'); //The Aptean Workcenter is complete

                    if (srtwfresp.requiresIssueResolution) { //And the work center is one where we need to resolve issues
                        isReadyToComplete = isReadyToComplete && !!!(srtwfresp.openIssues ?? []).length; //There are no open issues
                    } 

                    await this.setState({
                        salesOrderTrackingId: sotid,
                        workCenterId: wcid,
                        workflowTracking: srtwfresp,
                        isCompleted: srtwfresp.workCenterComplete,
                        isReadyToComplete: isReadyToComplete,
                        completionPercentage: completionPercentage,
                        loading: false
                    });
                }

            } else {

                if (!!wcid && !!shpid) {
                    let [shpWFResp] = await Promise.all([util.fetch.js(ApiRoutes.workCenter.shippingTracking(wcid, shpid))]);

                    if (!!shpWFResp.error) {
                        let errors = {};
                        errors["Exception"] = shpWFResp.error;

                        await this.setState({
                            loading: false,
                            formIsError: true,
                            errors: errors
                        });
                    } else {

                        let srtwfresp = shpWFResp.salesOrderTrackingWorkFlow;

                        let completionPercentage = 0;
                        let formTotal = srtwfresp.totalFormCount;
                        let completedCount = srtwfresp.completedFormCount;

                        if (formTotal != 0) {
                            completionPercentage = Math.round((completedCount / formTotal) * 100);
                        } else {
                            completionPercentage = 100;
                        }

                        let isReadyToComplete = (((formTotal == completedCount) || formTotal == 0) //All forms are complete
                            && (!!!(srtwfresp.openIssues ?? []).length) //There are no open issues
                            && srtwfresp.requiresIssueResolution //And the work center is one where we need to resolve issues
                        ) && !!(srtwfresp.workCenterComplete) && (srtwfresp.workCenterStatus !== 'Complete'); //The Aptean Workcenter is complete

                        var defaulttab = '1';

                        if (!!!(srtwfresp.openIssues ?? []).length) {
                            defaulttab = '2';

                            if (!!!(srtwfresp.workflowSurveys ?? []).length) {
                                defaulttab = '3';
                            }
                        }

                        await this.setState({
                            salesOrderTrackingId: srtwfresp.salesOrderTrackingId,
                            workCenterId: wcid,
                            workflowTracking: srtwfresp,
                            shippingTracking: shpWFResp.shipping,
                            isCompleted: srtwfresp.workCenterComplete,
                            isReadyToComplete: isReadyToComplete,
                            completionPercentage: completionPercentage,
                            activeTab: defaulttab,
                            loading: false
                        });
                    }

                } else {
                    this.notifyError("Missing required Ids to load form");
                }
            }
        }
    }

    onCompleteWorkCenter = async () => {
        const { wcid } = { ...this.props.match.params };
        let { workflowTracking, salesOrderTrackingId } = { ...this.state };
        this.setState({ saving: true });

        let dto = {
            workCenterId: wcid,
            workCenterSurveyId: 0,
            salesOrderTrackingId: salesOrderTrackingId
        };

        try {
            let response = await util.fetch.post(ApiRoutes.workCenter.workflowTrackingComplete(wcid, salesOrderTrackingId), dto, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();

                    workflowTracking.workCenterStatus = "Complete";

                    this.setState({
                        workflowTracking: workflowTracking,
                        isCompleted: true,
                        isReadyToComplete: false
                    });

                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400 || response.status == 500) {
                    let serviceResponse = await response.json();
                    let errors = {};
                    errors["Exception"] = serviceResponse.error;
                    this.setState({ errors: errors });
                } else {
                    let errors = {};
                    errors["Exception"] = await response.error();
                    this.setState({ errors: errors });
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
        
    }

    async toggle(tab) {
        //let { hasLoaded } = { ...this.state };
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                asyncActive: true
            });
            //tab post processing goes here
        }
    }

    onNavigateToSurvey = async (survey) => {
        let { shpid } = { ...this.props.match.params };
        let { salesOrderTrackingId, workCenterId, forShipping } = { ...this.state };

        if (survey.salesOrderTrackingSurveyId == 0) {
            try {

                let dto = {
                    workCenterId: workCenterId,
                    workCenterSurveyId: survey.workCenterSurveyId,
                    salesOrderTrackingId: salesOrderTrackingId
                };

                let response = await util.fetch.post(ApiRoutes.workCenter.createWorkCenterSurvey(), dto, util.fetch.format.none);
                if (response.redirected) {
                    window.location.href = response.url;
                } else if (!!response.ok) {
                    let surveyId = await response.json();

                    if (forShipping) {
                        this.props.history.push(`${AppNavPaths.Shipping}/${workCenterId}/${shpid}/${surveyId}`);
                    } else {
                        this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${salesOrderTrackingId}/${surveyId}`);
                    }
                } else {

                    if (response.status === 400 || response.status == 500) {
                        let serviceResponse = await response.json();
                        console.log(serviceResponse);
                        let errors = {};
                        errors["Exception"] = serviceResponse.error;
                        this.setState({ errors: errors });
                    } else {
                        let errors = {};
                        errors["Exception"] = await response.error();
                        this.setState({ errors: errors });
                    }
                    this.clearSaving();
                }
            } catch (error) {
                this.notifyError(error.toString());
                this.clearSaving();
            } 
        } else {
            if (forShipping) {
                this.props.history.push(`${AppNavPaths.Shipping}/${workCenterId}/${shpid}/${survey.salesOrderTrackingSurveyId}`)
            } else {
                this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${salesOrderTrackingId}/${survey.salesOrderTrackingSurveyId}`)
            }
        }
    }

    transformSurvey = (survey, ind) => {
        //total being set to -1 means there were no required questions on the form so progress 
        //should not be reported because it is pointless.
        let total = survey.totalRequiredQuestions;
        let complete = survey.totalRequiredQuestionsComplete;

        let prog = 0;
        let color = "danger";

        if (complete > total && total != -1) {
            complete = total;
        }

        if (total != -1) {
            prog = Math.round((complete / total) * 100);
            if (prog > 50) { color = "warning" }
            if (prog == 100) { color = "success" }
        }
        
        return (
            <Col key={survey.workCenterSurveyId.toString() + ind.toString() + 'workFlowSurvey'} xs={12} sm={6} md={4} lg={2} style={{ paddingBottom: '1em' }}>
                <Card style={{ textAlign: "center", height: "10.5em", backgroundColor: survey.surveyStatusId == 2 ? "#ebffd9" : "#inherit" }} >
                    <CardBody>
                        {(total != -1) &&
                            <div>

                                <Progress
                                    className="my-2"
                                    color={color}
                                    value={prog}
                                    style={{ position: 'relative' }}
                                >
                                    <small style={{ color: prog > 55 ? 'white' : 'black', fontWeight: 'bold' }} className={"justify-content-center d-flex position-absolute w-100"}>{`${prog}%`}</small>
                                </Progress>

                            </div>
                        }
                        {(total == -1) &&
                            <div style={{height: '2.5em'}} ></div>
                        }
                        <div>
                            <FontAwesomeIcon
                                className="text-dark zoom"
                                onClick={() => { this.onNavigateToSurvey(survey) }}
                                icon="fa-tasks" />
                        </div>
                        <CardText style={{ textDecoration: survey.deleted ? "line-through" : "inherit", color: survey.deleted ? "red" : "inherit" }} >{survey.surveyName}</CardText>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    updatePickList = async (e, id, dtl) => {
        let { shippingTracking, workCenterId } = { ...this.state };

        let detail = shippingTracking.shippingDetails.find((d) => d.id == dtl);
        let picklist = detail.pickList.find((pl) => pl.id == id);

        picklist.isCompleted = e.target.checked;
        picklist.completedBy = 'You';
        picklist.completedOn = moment();

        if (picklist.isCompleted) {
            await util.fetch.put(ApiRoutes.workCenter.shipping.picklistComplete(workCenterId, id), null, util.fetch.format.none);
        } else {
            await util.fetch.put(ApiRoutes.workCenter.shipping.picklistClear(workCenterId, id), null, util.fetch.format.none);
        }

        await this.setState({
            shippingTracking: shippingTracking
        });
    }

    //transformSection = (section, ind) => {
    //    let total = section.questionCount;
    //    let count = section.questionsAnsweredCount;

    //    if (total == 0) {
    //        total = 1;
    //    }
    //    if (count > total) {
    //        count = total;
    //    }

    //    let prog = Math.round((count / total) * 100);
    //    let color = "danger";
    //    if (prog > 50) { color = "warning" }
    //    if (prog == 100) { color = "success" }

    //    return (
    //        <AccordionItem key={section.id.toString() + ind.toString() + 'surveySection'} >
    //            <AccordionHeader targetId={ind} >{section.sectionName}</AccordionHeader>
    //            <AccordionBody accordionId={ind} >
    //                <div>
    //                    <Progress
    //                        className="my-2"
    //                        color={color}
    //                        value={prog}
    //                        style={{ position: 'relative' }}
    //                    >
    //                        <small style={{ color: 'black' }} className={"justify-content-center d-flex position-absolute w-100"}>{`${count}/${total}`}</small>
    //                    </Progress>
    //                </div>
    //                <div>{`${count} of ${total} questions answered`}</div>
    //            </AccordionBody>
    //        </AccordionItem>
    //    )
    //}

    render() {

        let {
            salesOrderTrackingId,
            workCenterId,
            workflowTracking,
            formIsError,
            isCompleted,
            isReadyToComplete,
            completionPercentage,
            loading,
            saving,
            errorResponse,
            forShipping,
            shippingTracking
        } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            if (forShipping) {
                return (
                    <Fragment>
                        <AppPageForm
                            formId={"workFlowSalesOrderTrackingForm"}
                            formHeadingIcon={workflowTracking.iconName ?? 'fa-building'}
                            formHeading={workflowTracking.workCenterName}
                            formName={"workFlowSalesOrderTrackingForm"}
                            formRef={this.formRef}
                            saving={this.state.saving}
                            errors={this.state.errors}
                            loading={loading}
                            showThisValidation={false}
                            onClearErrors={this.onClearErrorNotificationClicked}
                            onClearWarnings={this.onClearWarningNotificationClicked}
                        >
                            {!!!formIsError &&
                                <>
                                    <Container style={{ backgroundColor: "#e8e6df" }}>
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Shipping Number:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{shippingTracking.shippingNumber}</span>
                                            </Col>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Open Issues:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{workflowTracking.openIssueCount}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Serial Numbers:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span id="serialNumberDisplay">{
                                                    shippingTracking.shippingDetails.map(sd => sd.serialNumber).toString()
                                                }</span>
                                            </Col>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Completion Status:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{workflowTracking.workCenterStatus}</span>
                                                {' '}
                                                {workflowTracking.totalFormCount != 0 &&
                                                    <span>{`(${completionPercentage}%)`}</span>
                                                }
                                            </Col>
                                        </Row>
                                        {workflowTracking.workCenterMapped &&
                                            <Row>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                </Col>
                                                <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                </Col>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                    <span style={{ fontWeight: "bold" }} >Aptean Complete:</span>
                                                </Col>
                                                <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                    {isCompleted &&
                                                        <span>Yes</span>
                                                    }
                                                    {!isCompleted &&
                                                        <span>No</span>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Container>
                                    <Container>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <Nav tabs>
                                                    {!!(workflowTracking.openIssues ?? []).length &&
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                                onClick={async (event) => {
                                                                    event.persist();
                                                                    await this.toggle('1');
                                                                }}
                                                            >Open Issues</NavLink>
                                                        </NavItem>
                                                    }
                                                    {!!(workflowTracking.workflowSurveys ?? []).length &&
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                                onClick={async (event) => {
                                                                    event.persist();
                                                                    await this.toggle('2');
                                                                }}
                                                            >Forms</NavLink>
                                                        </NavItem>
                                                    }
                                                    {!!(shippingTracking.shippingDetails ?? []).length &&
                                                        shippingTracking.shippingDetails.map((sd, idx) => 
                                                            <NavItem key={uuid()}>
                                                                <NavLink
                                                                    className={classnames({ active: this.state.activeTab === (idx+3).toString() })}
                                                                    onClick={async (event) => {
                                                                        event.persist();
                                                                        await this.toggle((idx + 3).toString());
                                                                    }}
                                                                >{sd.serialNumber}</NavLink>
                                                            </NavItem>
                                                        )
                                                    }
                                                </Nav>
                                                <TabContent activeTab={this.state.activeTab} style={{ paddingTop: '1em' }} >
                                                    {!!(workflowTracking.openIssues ?? []).length &&
                                                        <TabPane tabId="1">
                                                            <Row>
                                                                <Table style={{ backgroundColor: "#e8e6df" }}>
                                                                    <colgroup>
                                                                        <col style={{ width: "5%" }} />
                                                                        <col style={{ width: "17%" }} />
                                                                        <col style={{ width: "17%" }} />
                                                                        <col style={{ width: "17%" }} />
                                                                        <col style={{ width: "17%" }} />
                                                                        <col style={{ width: "22%" }} />
                                                                        <col style={{ width: "5%" }} />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Work Center</th>
                                                                            <th>Form</th>
                                                                            <th>Section</th>
                                                                            <th>Question</th>
                                                                            <th>Description</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {!!(workflowTracking.openIssues ?? []).length &&
                                                                            workflowTracking.openIssues.map((isu, ind) =>
                                                                                <tr key={isu.id.toString() + ind.toString() + 'openIssue'}>
                                                                                    <td>{isu.id}</td>
                                                                                    <td>{isu.workCenterName}</td>
                                                                                    <td>{isu.surveyName}</td>
                                                                                    <td>{isu.sectionName}</td>
                                                                                    <td>{isu.questionText}</td>
                                                                                    <td>{isu.answerNotes}</td>
                                                                                    <td>
                                                                                        <FontAwesomeIcon
                                                                                            className="mr-2 text-dark pr-2 zoom"
                                                                                            onClick={() => { this.props.history.push(`${AppNavPaths.IssueTracking}/${isu.id}`) }}
                                                                                            icon="fa-pencil" />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        {!!!(workflowTracking.openIssues ?? []).length &&
                                                                            <tr><td colSpan={6}>No Open Issues</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Row>
                                                        </TabPane>
                                                    }
                                                    {!!(workflowTracking.workflowSurveys ?? []).length &&
                                                        <TabPane tabId="2">
                                                            <Row>
                                                                {!!(workflowTracking.workflowSurveys ?? []).length &&
                                                                    workflowTracking.workflowSurveys.map((svy, ind) => this.transformSurvey(svy, ind))
                                                                }
                                                            </Row>
                                                        </TabPane>
                                                    }
                                                    {!!(shippingTracking.shippingDetails ?? []).length &&
                                                        shippingTracking.shippingDetails.map((sd, idx) =>
                                                            <TabPane key={uuid()} tabId={(idx + 3).toString()} >
                                                                <Row>
                                                                    <Col sm={12} md={12} lg={12} >
                                                                        <Table style={{layout: 'fixed'}} id={'pickListTable' + (idx + 3).toString()} striped>
                                                                            <colgroup>
                                                                                <col style={{ width: '10%' }} />
                                                                                <col style={{ width: '30%' }} />
                                                                                <col style={{ width: '60%' }} />
                                                                            </colgroup>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Qty</th>
                                                                                    <th>Item Id</th>
                                                                                    <th>Item</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {!!(sd.pickList ?? []).length &&
                                                                                    (sd.pickList ?? []).sort((pl1,pl2) => pl2.order - pl1.order).map((pl) =>
                                                                                        <tr key={pl.id} >
                                                                                            <td>
                                                                                                <FormGroup check inline >                                                                                                    
                                                                                                    <Input type="checkbox" className="mr-3" checked={!!pl.isCompleted} data-id={pl.id} disabled={workflowTracking.workCenterStatus == "Complete"} onChange={(e) => this.updatePickList(e, pl.id, sd.id)} />
                                                                                                    {pl.requiredQty}
                                                                                                    {!!pl.isCompleted &&
                                                                                                        <Tooltip title={`Completed by ${pl.completedBy} ${moment.utc(pl.completedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                                            <small className="text-muted font-italic">{pl.completedBy}</small>
                                                                                                        </Tooltip>}
                                                                                                </FormGroup>
                                                                                            </td>
                                                                                            <td>{pl.component_Item_ID}</td>
                                                                                            <td>{pl.component_Item}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                                {!!!(sd.pickList ?? []).length &&
                                                                                    <tr>
                                                                                        <td colSpan="3">No Pick List</td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Row>    
                                                            </TabPane>
                                                        )
                                                    }
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                            }
                            <FlexCenterRow className="mb-3" style={{ marginTop: "2em" }}>
                                {!!(isReadyToComplete) && !!(isCompleted) &&
                                    shippingTracking.shippingDetails.every(
                                        (sd) => sd.pickList.every((pl) => pl.isCompleted)
                                    ) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="formForm"
                                        className="ml-2"
                                        disabled={!!saving}
                                        onClick={() => this.onCompleteWorkCenter()}
                                    >
                                        <FontAwesomeIcon
                                            icon={saving ? "fa-circle-check" : "fa-save"}
                                            className={saving ? "mr-2 faSaveSpinner" : "mr-2"}
                                        />
                                        {saving ? 'Saving, Please Wait...' : 'Complete Work Center'}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="formFormClose"
                                    onClick={this.onCloseClicked}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon="fa-times" />
                                    {"Close"}
                                </Button>
                                <Button
                                    size="lg"
                                    type="button"
                                    color="secondary"
                                    name="showSalesOrderTracking"
                                    onClick={this.onShowSalesOrderTrackingClicked}
                                    className="ml-2"
                                    id="showSalesOrderTrackingButton"
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon="fa-chart-line" />
                                    {"Show Order Tracking"}
                                </Button>
                            </FlexCenterRow>
                            <FlexRow style={{ marginTop: "2em" }} >
                                <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                            </FlexRow>
                        </AppPageForm>
                        {
                            <>
                                {
                                    <SalesOrderTrackingSlideForm
                                        id="salesOrderTrackingSlideForm"
                                        ref={this.salesOrderTrackingSlideFormRef}
                                        salesOrderTrackingId={salesOrderTrackingId}
                                        workCenterId={workCenterId}
                                        routingHistory={this.props.history}
                                    />
                                }
                            </>
                        }
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <AppPageForm
                            formId={"workFlowSalesOrderTrackingForm"}
                            formHeadingIcon={workflowTracking.iconName ?? 'fa-building'}
                            formHeading={workflowTracking.workCenterName}
                            formName={"workFlowSalesOrderTrackingForm"}
                            formRef={this.formRef}
                            saving={this.state.saving}
                            errors={this.state.errors}
                            loading={loading}
                            showThisValidation={false}
                            onClearErrors={this.onClearErrorNotificationClicked}
                            onClearWarnings={this.onClearWarningNotificationClicked}
                        >
                            {!!!formIsError &&
                                <>
                                    <Container style={{ backgroundColor: "#e8e6df" }}>
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Model:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{workflowTracking.model}</span>
                                            </Col>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Open Issues:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{workflowTracking.openIssueCount}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Serial Number:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span id="serialNumberDisplay">{workflowTracking.serialNumber}</span>
                                                <Can I="view" a="sales_order_tracking">
                                                    <FontAwesomeIcon
                                                        className="mr-2 text-dark pr-2 zoom"
                                                        onClick={() => { this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${salesOrderTrackingId}`) }}
                                                        icon="fa-eye" />
                                                </Can>
                                            </Col>
                                            <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                <span style={{ fontWeight: "bold" }} >Completion Status:</span>
                                            </Col>
                                            <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                <span>{workflowTracking.workCenterStatus}</span>
                                                {' '}
                                                {workflowTracking.totalFormCount != 0 &&
                                                    <span>{`(${completionPercentage}%)`}</span>
                                                }
                                            </Col>
                                        </Row>
                                        {workflowTracking.workCenterMapped &&
                                            <Row>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                </Col>
                                                <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                </Col>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                                    <span style={{ fontWeight: "bold" }} >Aptean Complete:</span>
                                                </Col>
                                                <Col xs={8} sm={8} md={10} lg={4} xl={4} xxl={4}>
                                                    {isCompleted &&
                                                        <span>Yes</span>
                                                    }
                                                    {!isCompleted &&
                                                        <span>No</span>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </Container>
                                    {!!(workflowTracking.workflowSurveys ?? []).length &&
                                        <Container style={{ marginTop: "2em" }} >
                                            <Row>
                                                <h4>Open Issues</h4>
                                            </Row>
                                            <Row>
                                                <Table style={{ backgroundColor: "#e8e6df" }}>
                                                    <colgroup>
                                                        <col style={{ width: "5%" }} />
                                                        <col style={{ width: "17%" }} />
                                                        <col style={{ width: "17%" }} />
                                                        <col style={{ width: "17%" }} />
                                                        <col style={{ width: "17%" }} />
                                                        <col style={{ width: "22%" }} />
                                                        <col style={{ width: "5%" }} />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Work Center</th>
                                                            <th>Form</th>
                                                            <th>Section</th>
                                                            <th>Question</th>
                                                            <th>Description</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!!(workflowTracking.openIssues ?? []).length &&
                                                            workflowTracking.openIssues.map((isu, ind) =>
                                                                <tr key={isu.id.toString() + ind.toString() + 'openIssue'}>
                                                                    <td>{isu.id}</td>
                                                                    <td>{isu.workCenterName}</td>
                                                                    <td>{isu.surveyName}</td>
                                                                    <td>{isu.sectionName}</td>
                                                                    <td>{isu.questionText}</td>
                                                                    <td>{isu.answerNotes}</td>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            className="mr-2 text-dark pr-2 zoom"
                                                                            onClick={() => { this.props.history.push(`${AppNavPaths.IssueTracking}/${isu.id}`) }}
                                                                            icon="fa-pencil" />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {!!!(workflowTracking.openIssues ?? []).length &&
                                                            <tr><td colSpan={6}>No Open Issues</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Row>

                                            <Row>
                                                {!!(workflowTracking.workflowSurveys ?? []).length &&
                                                    workflowTracking.workflowSurveys.map((svy, ind) => this.transformSurvey(svy, ind))
                                                }
                                            </Row>
                                        </Container>
                                    }
                                </>
                            }
                            <FlexCenterRow className="mb-3" style={{ marginTop: "2em" }}>
                                {!!(isReadyToComplete) && !!(isCompleted) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="formForm"
                                        className="ml-2"
                                        disabled={!!saving}
                                        onClick={() => this.onCompleteWorkCenter()}
                                    >
                                        <FontAwesomeIcon
                                            icon={saving ? "fa-circle-check" : "fa-save"}
                                            className={saving ? "mr-2 faSaveSpinner" : "mr-2"}
                                        />
                                        {saving ? 'Saving, Please Wait...' : 'Complete Work Center'}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="formFormClose"
                                    onClick={this.onCloseClicked}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon="fa-times" />
                                    {"Close"}
                                </Button>
                                <Button
                                    size="lg"
                                    type="button"
                                    color="secondary"
                                    name="showSalesOrderTracking"
                                    onClick={this.onShowSalesOrderTrackingClicked}
                                    className="ml-2"
                                    id="showSalesOrderTrackingButton"
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon="fa-chart-line" />
                                    {"Show Order Tracking"}
                                </Button>
                            </FlexCenterRow>
                            <FlexRow style={{ marginTop: "2em" }} >
                                <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                            </FlexRow>
                        </AppPageForm>
                        {
                            <>
                                {
                                    <SalesOrderTrackingSlideForm
                                        id="salesOrderTrackingSlideForm"
                                        ref={this.salesOrderTrackingSlideFormRef}
                                        salesOrderTrackingId={salesOrderTrackingId}
                                        workCenterId={workCenterId}
                                        routingHistory={this.props.history}
                                    />
                                }
                            </>
                        }
                    </Fragment>
                )
            }
        }
    }
}